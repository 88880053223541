<!--
 * @Author: Dyf
 * @Date: 2023-06-14 18:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024/04/19
 * @Descripttion: 【教学-授课】上课
-->
<style lang="scss" scoped>
.attend-class {
  @include innerPage;
  @include pageHead(-42px);
  width: calc(100% - 100px) !important;
  margin-left: 100px !important;
  .page-head{
    height: 100px;
    .bitmap{
      width: 130px;
    }
    .head-wrapper{
      margin-left: -20px;
    }
    .head {
      height: 50px;
      box-sizing: border-box;
      @include flexBox(flex-end);

      .el-button {
        height: 48px;

        .iconfont {
          font-size: 24px;
        }
      }

      .el-button--text {
        height: 46px;
        line-height: 46px;
        margin-right: 10px;

        .iconfont {
          width: 46px;
          height: 46px;
          border-radius: 50%;
          display: inline-block;
          text-align: center;
          line-height: 46px;
          font-size: 30px;
          color: #6340c8;
          background: rgba($color: #6340c8, $alpha: 0.15);
          margin-right: 10px;
        }

        span {
          color: #252525;
        }

        &:hover {
          span {
            color: #6340c8;
          }
        }
      }
    }
  } 
  
  .page-inner {
    width: 100%;
    height: calc(100% - 90px);
    box-sizing: border-box;
    padding-top: 20px;

    .wrapper {
      width: calc(100% + 20px);
      height: 100%;
      box-sizing: border-box;
      padding-right: 20px;
      overflow: hidden;
      overflow-y: auto;
      @include flexBox(space-between, flex-start);

      &-left {
        width: calc(100% - 334px);
        height: 100%;
        .body-box {
          width: 100%;
          height: 100%;
          @include flexBox;

          .courseware-wrapper {
            flex-grow: 1;
            height: 100%;
            margin-right: 30px;

            &.develop {
              margin-right: 0;

              .show-box .question {
                &-wrapper {
                  padding: 28px 30px;
                  .operation {
                    background-color: #ffffff;
                    padding: 10px 10px 10px 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 10px;
                    margin-bottom: 20px;
                    width: 100%;
                    box-sizing: border-box;
                    position: sticky;
                    top: 0;
                    z-index: 20;
                  }
                }
              }
            }

            .show-box {
              width: 100%;
              height: calc(100% - 60px);
              overflow-y: auto;
              background: #f0f3fa;
              border-radius: 10px;

              &.show-courseware {
                height: 100%;
              }

              .courseware {
                width: 100%;
                height: 100%;
                overflow: hidden;
                position: relative;

                &.full-screen {
                  position: fixed;
                  top: 0;
                  left: 0;
                  z-index: 500;
                }
                iframe{
                  width: 100%;
                  height: 100%;
                  border:0;
                }
                .board-box {
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 49;
                  visibility: hidden;
                  &.show {
                    visibility: visible;
                  }
                }
                .courseware-tool {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  position: absolute;
                  bottom: 24px;
                  right: 0;
                  z-index: 50;
                  overflow: hidden;
                  transition: all 0.6s;
                  .tool-item {
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    line-height: 40px;
                    color: #6340c8;
                    background: rgb(213, 205, 234);
                    position: relative;
                    cursor: pointer;
                    .iconfont {
                      font-size: var(--size);
                    }
                    & + .tool-item::before {
                      content: "";
                      width: 20px;
                      height: 1px;
                      background: linear-gradient(
                        90deg,
                        rgba(99, 64, 200, 0) -4%,
                        #6340c8 48%,
                        rgba(99, 64, 200, 0) 100%
                      );
                      position: absolute;
                      top: -1px;
                      left: 50%;
                      margin-left: -10px;
                    }
                    &.act {
                      color: #fff;
                      background: #6340c8;
                      &::before {
                        opacity: 0;
                      }
                    }
                  }
                  .slide {
                    width: 24px;
                    height: 24px;
                    color: #fff;
                    background: #2ac293;
                    text-align: center;
                    line-height: 24px;
                    cursor: pointer;
                    .iconfont {
                      font-size: 12px;
                    }
                  }
                  &:hover {
                    opacity: 1;
                  }
                  &.packup {
                    .tool-item{
                      height: 0 !important;
                    }
                    // transform: translateY(calc(100% - 24px));
                    // height: 24px
                  }
                }
              }

              .question,
              .question-compose {
                @include flexBox;
                align-items: flex-start;
                width: 100%;
                height: calc(100% - 92px);
                position: relative;
                background-color: #ffffff;
                border-radius: 10px;
                padding: 10px 10px 10px 20px;
                box-sizing: border-box;
                margin-bottom: 30px;
                .checkbox {
                  display: flex;
                  align-items: baseline;
                  height: 24px;
                  width: 24px;
                  margin-top: 10px;
                  box-sizing: border-box;
                }
                ::v-deep {
                  .el-icon-success {
                    font-size: 24px;
                    color: #6c4ecb;
                  }
                  .el-checkbox {
                    padding-right: 0px;
                    margin-top: 0px;
                  }
                }

                &-wrapper {
                  width: 100%;
                  box-sizing: border-box;
                  padding: 28px 46px 0;
                  position: relative;
                  .magnifyicon{
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    line-height: 40px;
                    color: #6340c8;
                    background: #d5cdea;
                    cursor: pointer;
                    right: 0;
                    top: 0;
                    border-radius: 0 0 0 18px;
                  }
                  h3 {
                    color: #414141;
                    line-height: 42px;
                    margin-bottom: 20px;
                    text-align: center;
                  }
                }

                &--type {
                  flex-shrink: 0;
                  height: 34px;
                  line-height: 34px;
                  background: #6340c8;
                  border-radius: 12px 0px 12px 12px;
                  text-align: center;
                  color: #fff;
                  margin: 4px 8px 0 4px;
                  font-size: 18px;
                  padding: 0 14px;
                }
                &--num {
                  flex-shrink: 0;
                  line-height: 24px;
                  color: #1f1f1f;
                  font-size: 20px;
                  line-height: 34px;
                  width: 44px;
                  text-align: right;
                  margin-top: 4px;
                }
                &--data {
                  flex-grow: 1;

                  h5 {
                    line-height: 42px;
                    font-weight: bold;
                    color: #1f1f1f;
                    @include flexBox;
                    align-items: flex-start;
                    .select{
                      position: absolute;
                      right: 10px;
                      top: 10px;
                      width: 34px;
                      height: 34px;
                      text-align: center;
                      line-height: 34px;
                      border-radius: 50%;
                      background-color: #2AC293;
                      color: #FFFFFF;
                      font-size: 22px;
                      opacity: 0.2;
                      z-index:5;
                      &:hover{
                        cursor: pointer;
                        opacity: 1;
                      }
                    }
                    span {
                      text-align: justify;
                      margin-right: 10px;
                      margin-left: 10px;
                      font-size: 24px;
                    }

                    .el-image {
                      flex-shrink: 0;
                      margin-top: -7px;
                    }
                  }
                }

                &--options {
                  margin-top: 12px;

                  .option {
                    line-height: 30px;
                    color: #666666;
                    @include flexBox;
                    align-items: flex-start;
                    margin: 10px 0;
                    font-size: 18px;
                    .el-image {
                      margin-left: 10px;
                      flex-shrink: 0;
                      margin-top: -4px;
                    }
                  }
                }
                .compose-item {
                  margin: 16px 0 0;
                  h5 {
                    line-height: 42px;
                    color: #1f1f1f;
                    @include flexBox;
                    align-items: flex-start;
                    padding-bottom: 16px;
                    position: relative;
                    span {
                      text-align: justify;
                      margin-right: 10px;
                    }

                    .el-image {
                      flex-shrink: 0;
                      margin-top: -7px;
                    }
                  }
                  .question--options {
                    margin-left: 8px;
                  }
                }
                ::v-deep {
                  .el-loading-mask {
                    background: #f0f3fa;
                  }
                }
              }
            }

            .operation-btns {
              height: 60px;
              box-sizing: border-box;
              padding-top: 18px;
              @include flexBox;
              align-items: baseline;

              .change-btn {
                margin-left: auto;
              }
            }
          }

          .drawing {
            width: 0;
            height: 100%;
            overflow: hidden;
            flex-shrink: 0;

            &.show {
              width: 370px;
            }
          }
        }
      }

      &-right {
        width: 304px;
        height: 100%;
        box-sizing: border-box;
        flex-direction: column;
        @include flexBox;
      }
    }

    .operation-board {
      width: 100%;
      max-height: 190px;
      flex-shrink: 0;
      background: #f0f3fa;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 30px 0;
      flex-direction: column;
      @include flexBox(space-between, center);

      .el-button {
        width: 134px;
        min-height: 38px;
        margin: 6px 0 0;
      }
    }

    .students {
      width: 100%;
      min-height: calc(100% - 220px);
      max-height: calc(100% - 128px);
      flex-grow: 1;
      margin-top: 30px;
      background: #f0f3fa;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 24px 18px;
      flex-direction: column;
      position: relative;
      @include flexBox(space-between);
      .extract{
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
       
        .iconfont{
          display: inline-block;
          font-size: 20px;
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          color: #6340c8;
          background: rgb(213, 205, 234);
          cursor: pointer;
          border-radius: 0 0 0 15px;
        }
      }
      .statistics-board {
        width: 100%;
        height: 130px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba($color: #bcbcbc, $alpha: 0.34);
        flex-shrink: 0;
        flex-direction: column;
        @include flexBox(center, flex-start);

        p {
          width: calc(100% - 26px);
          height: 34px;
          color: #2e2e2e;
          @include flexBox(space-between);

          span {
            &:first-child {
              font-size: 16px;
            }
          }

          b {
            font-size: 24px;
          }
        }
      }

      .submitNumber {
        width: 100%;
        display: flex;
        justify-content: space-around;
        padding: 10px 0;
        .item {
          text-align: center;
          .title {
            line-height: 22px;
            margin-bottom: 4px;
            font-size: 16px;
            color: #666666;
          }
          .p {
            color: #6340c8;
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
      .student-list {
        width: calc(100% + 8px);
        flex-grow: 1;
        margin-top: 30px;
        box-sizing: border-box;
        padding: 0 16px;
        overflow: hidden;
        overflow-y: auto;

        li {
          width: 100%;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }
          .stuser_info {
            height: 62px;
            margin-bottom: 10px;
            @include flexBox;
          }
          .el-avatar {
            flex-shrink: 0;
          }
          .widht100 {
            width: 100%;
          }
          .name_type {
            @include flexBox(space-between, center);
            padding-left: 10px;
          }
          .result {
            @include flexBox(space-between, center);
            padding-left: 10px;
            margin-top: 10px;
            .item {
              color: #ff0000;
            }
            .correct {
              color: green;
            }
          }
          .name {
            font-size: 16px;
            color: #2b2b2b;
            @include ellipsisMultiline(2);
          }

          .connect-status {
            color: rgba($color: #7a7a7a, $alpha: 0.39);
            flex-shrink: 0;
            @include flexBox;

            i {
              display: inline-block;
              width: 10px;
              height: 10px;
              background: rgba($color: #7a7a7a, $alpha: 0.39);
              border-radius: 50%;
              margin-right: 4px;
            }

            &.connected {
              color: #2ac293;

              i {
                background: #2ac293;
              }
            }
            &.submit {
              color: #6340c8;

              i {
                background: #6340c8;
              }
            }
          }
          .answer {
            display: inline-block;
            .item {
              margin: 0px 5px;
              float: left;
            }
            .tooltip-width {
              width: 200px;
            }
          }
        }
      }
    }
  }
}

.dialog-container--distribute {
  box-sizing: border-box;
  padding: 24px 0;

  .date-picker {
    ::v-deep {
      .el-input {
        width: 100%;

        &__inner {
          padding: 0 44px 0 26px;
        }
      }
    }
  }
}
.distribute-dialog .el-dialog {
  transform: translateY(-80%);
}
.selectimg {
  max-width: 100%;
  display: block;
}
.magnifying{
  .question{
    &--type{
      height: 44px !important;
      line-height: 44px !important;
      font-size: 28px !important;
      padding: 0 20px !important;
    }
    &--data h5{
      line-height: 52px !important;
    }
    &--num{
      line-height: 44px !important;
      font-size: 24px !important;
      width: 50px !important;
    }
    &--data h5 span{
      font-size: 30px !important;
    }
    &--options .option{
      font-size: 24px !important;
      line-height: 40px !important;
    }
  }
  .checkbox{
    margin-top: 15px !important;
  }
}
.single-answer{
  width: 100%;
  position: relative;
  max-height: 600px;
  max-height: calc(100vh - 300px);
  min-height: 330px;
  overflow: auto;
  margin-bottom: 30px;
  .refresh{
    position: fixed;
    bottom: 20px;
    right: 20px
  }
  .flextitle{
    display: flex;
    background: #FFFFFF;
    position: sticky;
    top: 0;
    z-index: 20;
    .allnumber{
      width: 200px;
      text-align: center;
      padding: 15px 0;
    }
    .number{
      flex: 1;
      text-align: center;
      padding: 15px 0;
    }
    h6{
      font-size: 16px;
      line-height: 34px;
    }
    strong{
      font-weight: bold;
      font-size: 18px;
      color: #6340C8;
      line-height: 30px;
    }
  }
  .student-list{
    widows: 100%;
    li{
      padding: 10px 0;
      display: flex;
      width: 100%;
      border-top: 1px solid #DDDDDD;
      .stuser_info{
        display: flex;
        width: 200px;
        align-items: center;
      }
      .name_answer{
        flex:1;
        padding-left: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        height: 30px;
        .name{
          font-size: 16px;
          color: #2b2b2b;  
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 120px;
        }
        .answer{
          margin-left: 5px;
        }
      }
      .date{
        padding-left: 10px;
        font-size: 14px;
        margin-top: 5px;
        line-height: 20px;
        color: #999999;
      }
      .result{
        flex: 1;
        display: flex;
        .type{
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          &.grow2{
            flex-grow: 2;
          }
          .dian{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            &.color1{
             background-color: green;
            }
            &.color2{
             background-color: #ff0000;
            }
            &.color3{
             background-color: #FEAF2F;
            }
          }
        }
      }
    }
  }
}

</style>

<template>
  <section class="attend-class">
    <div class="page-head">
      <img class="bitmap" src="@assets/images/bitmap-head.png" alt="" />
      <div class="head-wrapper">
        <breadcrumb />
        <div>
          <div class="head">
            <el-button
              type="text"
              @click="showDrawingBoard = !showDrawingBoard"
            >
              <i class="iconfont">&#xe646;</i>
              <span>白板</span>
            </el-button>
            <el-button type="custom_danger" @click="overClass">
              <i class="iconfont">&#xe644;</i>
              <span class="bold">下课</span>
            </el-button>
            <el-button type="warning" @click="resetap" icon="el-icon-refresh" size="medium">重启设备</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="page-inner">
      <div class="wrapper">
        <div class="wrapper-left">
          <!-- <div class="head">
            <el-button
              type="text"
              @click="showDrawingBoard = !showDrawingBoard"
            >
              <i class="iconfont">&#xe646;</i>
              <span>白板</span>
            </el-button>
            <el-button type="custom_danger" @click="overClass">
              <i class="iconfont">&#xe644;</i>
              <span class="bold">下课</span>
            </el-button>
          </div> -->
          <div class="body-box" ref="bodyBox">
            <div
              class="courseware-wrapper"
              :class="{ develop: !showDrawingBoard }"
            >
              <div
                class="show-box"
                :class="{ 'show-courseware': showType == 1 }"
              >
                <!-- 课件 -->
                <div
                  ref="screenBox"
                  class="courseware"
                  :class="{
                    'no-data': $isEmpty(courseware),
                    'full-screen': fullscreen,
                  }"
                  v-if="showType == 1"
                >
                  <template v-if="!$isEmpty(courseware)">
                    <iframe :src="`webffice/index.html?AccessToken=${courseware.accessToken}&WebofficeURL=${courseware.webofficeURL}`" />
                  </template>
                  <div class="no-data--empty" v-if="$isEmpty(courseware)">
                    <img src="@assets/images/no-data3.png" alt="" />
                    <p>暂无课件信息哦~</p>
                  </div>
                  <div class="courseware-tool" :class="{ packup }">
                    <div class="slide" @click="packup = !packup">
                      <span v-if="packup" class="iconfont">&#xe6cc;</span>
                      <span v-else class="iconfont">&#xe6cb;</span>
                    </div>
                    <!-- 随机问答 -->
                    <el-tooltip effect="dark" content="随机答题" placement="left">
                      <div class="tool-item" style="--size: 18px" @click="showStudent=true">
                          <span class="iconfont">&#xe65f;</span>
                      </div>
                    </el-tooltip>
                    <!-- 全屏/还原 -->
                    <el-tooltip effect="dark" v-if="!openboard" :content="fullscreen?'缩小':'全屏'" placement="left">
                      <div class="tool-item" style="--size: 18px" @click="setfullscreen()">
                        <span v-if="fullscreen" class="iconfont">&#xe68e;</span>
                        <span v-else class="iconfont">&#xe68c;</span>
                      </div>
                    </el-tooltip>
                    <!-- 打开画板 / 关闭画板 -->
                    <el-tooltip effect="dark" :content="openboard?'关闭画板':'开启画板'" placement="left">
                      <div
                        class="tool-item"
                        style="--size: 22px"
                        @click="openBoard"
                      >
                        <span v-if="openboard" class="iconfont">&#xe689;</span>
                        <span v-else class="iconfont">&#xe68b;</span>
                      </div>
                    </el-tooltip>
                    <template v-if="openboard">
                      <el-tooltip effect="dark" content="画笔" placement="left">
                        <div
                          class="tool-item"
                          :class="{ act: !is_eraser }"
                          style="--size: 22px"
                          @click="is_eraser = false"
                        >
                          <span class="iconfont">&#xe688;</span>
                        </div>
                      </el-tooltip>
                      <el-tooltip effect="dark" content="颜色" placement="left">
                        <div class="tool-item" style="--size: 18px" @click="is_eraser = false">
                          <el-color-picker
                           size="small"
                            v-model="pen_color"
                            :predefine="predefineColors">
                          </el-color-picker>
                        </div>
                      </el-tooltip>
                      
                      <el-popover
                        placement="left"
                        width="200"
                        trigger="click">
                        <el-slider
                          v-model="pen_w"
                          :step="1"
                          :max="10"
                          :min="2"
                          show-stops>
                        </el-slider>
                        <div slot="reference">
                          <el-tooltip effect="dark" content="粗细" placement="left">
                            <div class="tool-item" :style="'--size: '+(pen_w+12)+'px'" @click="is_eraser = false">
                              <span class="iconfont">&#xe666;</span>
                            </div>
                          </el-tooltip>
                        </div>
                      </el-popover>

                      <el-tooltip effect="dark" content="橡皮擦" placement="left">
                        <div class="tool-item" :class="{ act: is_eraser }" style="--size: 22px" @click="is_eraser = true" >
                          <span class="iconfont">&#xe68d;</span>
                        </div>
                      </el-tooltip>
                      <el-tooltip effect="dark" content="重绘" placement="left">
                        <div class="tool-item" style="--size: 22px" @click="[boardCheckClear(),is_eraser = false]">
                          <span class="iconfont">&#xe620;</span>
                        </div>
                      </el-tooltip>
                    </template>
                  </div>
                  <div
                    class="board-box"
                    id="boardBox"
                    :class="{ show: openboard }"
                    :style="{ width: `${board_w}px`, height: `${board_h}px` }" 
                    @touchmove.prevent
                  >
                    <canvas
                      id="board"
                      :width="board_w"
                      :height="board_h"
                      @mousedown="boardCanvasDown($event)"
                      @mouseup="boardCanvasUp($event)"
                      @mousemove="boardCanvasMove($event)"
                      @mouseleave="boardCanvasUp($event)"
                      @touchstart="boardCanvasDown($event)"
                      @touchend="boardCanvasUp($event)"
                      @touchmove="boardCanvasMove($event)"
                    />
                  </div>
                </div>
                <!-- 练习题 || 校内作业 -->
                <div class="question-wrapper" :class="fullscreen?'magnifying':''" v-if="showType != 1">
                  <!-- 全屏/还原 -->
                  <div class="magnifyicon" style="--size: 18px" @click="setfullscreen()" v-if="showType == 2" >
                    <span v-if="fullscreen" class="iconfont">&#xe68e;</span>
                    <span v-else class="iconfont">&#xe68c;</span>
                  </div>
                  <h3 class="bold">
                    {{ (showType == 2 && "课堂练习") || "校内作业" }}
                    <span v-if="sTime != 0" style="font-size: 14px"
                      >（答题剩余时间：{{ sTime }}秒）</span
                    >
                  </h3>

                  <div class="operation">
                    <el-checkbox
                      size="small"
                      :indeterminate="someCheck"
                      v-model="allCheck"
                      label="全选"
                      @change="allcheckchange"
                    ></el-checkbox>
                  </div>
                  <div
                    v-for="(current_que, current_index) in current_que_list"
                    :key="current_index"
                  >
                    <!-- 非组合提 -->
                    <div
                      class="question"
                      v-show="
                        !isCompose(current_que[`${param_prefix}ismakeup`])
                      "
                      v-loading="queload"
                    >
                      <div class="checkbox">
                        <i
                          class="el-icon-success"
                          v-if="current_que.is_assign == 1"
                        ></i>
                        <el-checkbox
                          v-else
                          v-model="current_que.check"
                          @change="
                            checkchangeitem(current_que.check, current_index)
                          "
                        ></el-checkbox>
                      </div>
                      <span class="question--num pf_bold">
                        {{
                          current_index < 9
                            ? "0" + (current_index + 1)
                            : current_index + 1
                        }}.
                      </span>
                      <span class="question--type">{{
                        current_que.syque_typ_name
                      }}</span>
                      <div class="question--data">
                        <h5 class="bold">
                          <span class="richinline" v-html="current_que[`${param_prefix}title`]"></span>
                          <div class="select" v-if="current_que.is_assign&&current_que.is_assign==1" @click="showSingleAnswer(current_que)" v-loading="showSingleAnswerLoading">
                            <i class="iconfont">&#xe61c;</i>
                          </div>
                        </h5>
                        <img
                          class="selectimg"
                          v-if="current_que[`${param_prefix}title_image`]"
                          :src="current_que[`${param_prefix}title_image`]"
                          alt=""
                        />
                        <ul
                          class="question--options"
                          v-show="isChoice(current_que.syque_typ_id)"
                        >
                          <template v-if="current_que.question_item">
                            <li
                              class="option"
                              v-for="o in current_que.question_item"
                              :key="o[`${param_prefix}ite_id`]"
                            >
                              <span class="flex">
                                {{ o[`${param_prefix}ite_code`] }}.
                                <em class="richinline" v-html="o[`${param_prefix}ite_title`]"></em>
                              </span>
                              <img
                                class="selectimg"
                                v-if="formatfile(o[`${param_prefix}ite_image`])"
                                :src="formatfile(o[`${param_prefix}ite_image`])"
                                alt=""
                              />
                            </li>
                          </template>
                          <template v-if="current_que.item">
                            <li
                              class="option"
                              v-for="o in current_que.item"
                              :key="o[`${param_prefix}ite_id`]"
                            >
                              <span class="flex">
                                {{ o[`${param_prefix}ite_code`] }}.
                                <em class="richinline" v-html="o[`${param_prefix}ite_title`]"></em>
                              </span>
                              <img
                                class="selectimg"
                                v-if="formatfile(o[`${param_prefix}ite_image`])"
                                :src="formatfile(o[`${param_prefix}ite_image`])"
                                alt=""
                              />
                            </li>
                          </template>
                        </ul>
                      </div>
                    </div>
                    <!-- 组合题 -->
                    <div
                      class="question-compose"
                      v-show="isCompose(current_que[`${param_prefix}ismakeup`])"
                      v-loading="queload"
                    >
                      <div class="checkbox">
                        <i
                          class="el-icon-success"
                          v-if="current_que.is_assign == 1"
                        ></i>
                        <el-checkbox
                          v-else
                          :indeterminate="current_que.someCheck"
                          v-model="current_que.check"
                          @change="
                            checkchangeitem(current_que.check, current_index)
                          "
                        ></el-checkbox>
                      </div>
                      <span class="question--num pf_bold">
                        {{
                          current_index < 9
                            ? "0" + (current_index + 1)
                            : current_index + 1
                        }}.
                      </span>
                      <span class="question--type">{{
                        current_que.syque_typ_name
                      }}</span>
                      <div class="question--data">
                        <h5 class="bold">
                          <span class="richinline" v-html="current_que[`${param_prefix}title`]"></span>
                        </h5>
                        <img
                          class="selectimg"
                          v-if="current_que[`${param_prefix}title_image`]"
                          :src="current_que[`${param_prefix}title_image`]"
                          alt=""
                        />
                        <div
                          v-for="(
                            childrenitem, que_compose_index
                          ) in current_que.children"
                          :key="que_compose_index"
                        >
                          <div
                            class="compose-item"
                            v-if="!$isEmpty(childrenitem)"
                          >
                            <h5>
                              <div class="checkbox">
                                <i
                                  class="el-icon-success"
                                  v-if="childrenitem.is_assign == 1"
                                ></i>
                                <el-checkbox :style="{marginTop:fullscreen?'-15px':'-10px'}"
                                  v-else
                                  v-model="childrenitem.check"
                                  @change="
                                    checkchangechilitem(
                                      current_index,
                                      que_compose_index
                                    )
                                  "
                                ></el-checkbox>
                              </div>
                              <span class="pf_bold">{{ que_compose_index + 1 }}、<em class="richinline" v-html="childrenitem[`${param_prefix}title`]"></em> </span>
                              <div class="select" v-if="childrenitem.is_assign&&childrenitem.is_assign==1" @click="showSingleAnswer(childrenitem)" v-loading="showSingleAnswerLoading">
                                <i class="iconfont">&#xe61c;</i>
                              </div>
                            </h5>
                            <img
                              class="selectimg"
                              v-if="
                                formatfile(
                                  childrenitem[`${param_prefix}title_image`]
                                )
                              "
                              :src="
                                formatfile(
                                  childrenitem[`${param_prefix}title_image`]
                                )
                              "
                              alt=""
                            />
                            <ul
                              class="question--options"
                              v-show="isChoice(childrenitem.syque_typ_id)"
                            >
                              <li
                                class="option"
                                v-for="o in childrenitem.item"
                                :key="o[`${param_prefix}ite_id`]"
                              >
                                <span class="flex">
                                  {{ o[`${param_prefix}ite_code`] }}.<em class="richinline" v-html="o[`${param_prefix}ite_title`]"></em>
                                </span>
                                <img
                                  class="selectimg"
                                  v-if="
                                    formatfile(o[`${param_prefix}ite_image`])
                                  "
                                  :src="
                                    formatfile(o[`${param_prefix}ite_image`])
                                  "
                                  alt=""
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="operation-btns" v-if="showType != 1">
                <el-button
                  type="custom_primary"
                  size="small"
                  v-if="showType == 2"
                  @click="distributeQue"
                  v-loading="btnload"
                >
                  确认下发
                </el-button>
                <el-button
                  type="custom_primary"
                  size="small"
                  v-if="showType == 3"
                  @click="distributeDialog = true"
                  v-loading="btnload"
                >
                  确认下发
                </el-button>
              </div>
            </div>
            <div class="drawing" :class="{ show: showDrawingBoard }">
              <drawingBoard :c_h="drawingBoardH" :c_w="370" />
            </div>
          </div>
        </div>
        <div class="wrapper-right">
          <div class="operation-board">
            <el-button
              :type="(showType == 1 && 'custom_success') || 'custom_primary'"
              size="small"
              @click="changeFile(1)"
            >
              映射课件
            </el-button>

            <el-button
              v-if="!$isEmpty(teachHomeworkData)"
              :type="(showType == 3 && 'custom_success') || 'custom_primary'"
              size="small"
              @click="showSetTime"
            >
              下发家庭作业
            </el-button>
            <el-button
              v-if="!$isEmpty(practiseList)"
              :type="(showType == 2 && 'custom_success') || 'custom_primary'"
              size="small"
              @click="changeFile(2)"
            >
              下发练习
            </el-button>
            <!-- <el-button
              v-if="!$isEmpty(teachHomework)"
              :type="(showType == 3 && 'custom_success') || 'custom_primary'"
              size="small"
              @click="changeFile(3)"
            >
              下发作业
            </el-button> -->
          </div>
          <!-- 学生连接信息 -->
          <div class="students">
            <!-- 随机问答 -->
            <div class="extract" @click="showStudent=true">
              <el-tooltip effect="dark" content="随机答题" placement="top">
                <span class="iconfont">&#xe65f;</span>
              </el-tooltip>
            </div>
            <div class="statistics-board" v-if="submitList.AllNum">
              <p>
                <span>学生总人数：</span>
                <span
                  ><b class="pf_bold">{{ submitList.AllNum }}</b
                  >人</span
                >
              </p>
              <p>
                <span>写字板学生人数：</span>
                <span
                  ><b class="pf_bold">{{ submitList.haveBoardNum }}</b
                  >人</span
                >
              </p>
              <p>
                <span>待连接人数：</span>
                <span
                  ><b class="pf_bold">{{ submitList.waitNum }}</b
                  >人</span
                >
              </p>
            </div>
            <!-- <div class="submitNumber">
                <div class="item">
                  <div class="title">提交人数</div>
                  <div class="p">{{submitList.submitNum}}</div>
                </div>
                <div class="item">
                  <div class="title">正确人数</div>
                  <div class="p">{{submitList.succNum}}</div>
                </div>
                <div class="item">
                  <div class="title">错误人数</div>
                  <div class="p">{{submitList.errorNum}}</div>
                </div>
            </div> -->
            <ul class="student-list">
              <li v-for="(item, index) in submitList.userList" :key="index">
                <div class="stuser_info">
                  <el-avatar :size="62" :src="item.stuser_image" />
                  <div class="widht100">
                    <div class="name_type">
                      <p class="name">{{ item.stuser_name }}</p>
                      <p
                        class="connect-status submit"
                        v-if="item.onLineState == 1"
                      >
                        <i></i>
                        <span>已连接</span>
                      </p>
                      <p class="connect-status" v-else>
                        <i></i>
                        <span>未连接</span>
                      </p>
                    </div>
                    <div class="result">
                      <div class="item correct">已答对:{{ item.succNum }}</div>
                      <div class="item">已答错:{{ item.errorNum }}</div>
                    </div>
                  </div>
                </div>
                <div class="answer">
                  <div
                    class="item"
                    v-for="(item2, index2) in item.images"
                    :key="index2"
                  >
                    <el-tooltip
                      transition="disabled"
                      effect="dark"
                      placement="top"
                    >
                      <template slot="content" v-if="item2.que_id">
                        <div>{{inIdgetindex(item2.que_id)}}</div>
                      </template>
                      <el-image
                        style="
                          width: 20px;
                          height: 30px;
                          border: 1px solid #999999;
                        "
                        v-if="item2.url"
                        fit="cover"
                        :src="item2.url"
                        :preview-src-list="[item2.url]"
                      />
                    </el-tooltip>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 设置提交作业时间 -->
    <el-dialog
      title="设置提交作业时间"
      width="430px"
      :visible.sync="setHomeTimedialog"
      class="distribute-dialog"
      @close="hideDialog"
    >
      <div class="dialog-container--distribute">
        <div class="date-picker">
          <el-date-picker
            v-model="distributeForm.teles_hom_deadlinetime"
            type="datetime"
            placeholder="请选择提交作业时间"
            prefix-icon="none"
            format="yyyy-MM-dd HH:mm"
            :picker-options="pickerOptions"
            @change="choosetime"
          />
        </div>
      </div>
      <div slot="footer">
        <el-button
          type="custom_primary"
          :disabled="!distributeForm.teles_hom_deadlinetime"
          size="small"
          @click="distributeHomework"
          v-loading="distributeLoding"
          >确认下发</el-button
        >
      </div>
    </el-dialog>

    <!-- 设置作业完成时间 -->
    <el-dialog
      title="设置作业完成时间"
      width="430px"
      :visible.sync="distributeDialog"
      class="distribute-dialog"
      @close="hideDistributeDialog"
    >
      <div class="dialog-container--distribute">
        <el-input
          v-model="home_finish_time"
          placeholder="请输入作业完成时间"
          @input="home_finish_time = iptInit(home_finish_time)"
        >
          <span slot="append">/分钟</span>
        </el-input>
      </div>
      <div slot="footer">
        <el-button
          v-loading="btnload"
          type="custom_primary"
          :disabled="!home_finish_time"
          size="small"
          @click="distributeQue"
        >
          确认下发
        </el-button>
      </div>
    </el-dialog>
    
    <!-- 查看小题学生答题时间 -->
    <el-dialog
      title="答题信息"
      width="600px"
      :visible.sync="singleAnswer"
      @close="singleAnswer=false"
    >
    <div class="single-answer">
      <div class="no-data--empty" v-if="$isEmpty(studentList)">
        <img src="@assets/images/no-data3.png" alt="" />
        <p>暂无答题信息哦~</p>
      </div>
      <div class="flextitle" v-if="total">
        <div class="allnumber">
          <h6>答题人数</h6>
          <strong>{{total}}</strong>
        </div>
        <div class="number" >
          <h6>{{isChoice(syque_typ_id)?"答对人数":"已答人数"}}</h6>
          <strong v-if="isChoice(syque_typ_id)">{{succNum}}</strong>
          <strong v-else>{{total-unanswered}}</strong>
        </div>
        <div class="number" v-if="isChoice(syque_typ_id)">
          <h6>答错人数</h6>
          <strong>{{errorNum}}</strong>
        </div>
        <div class="number">
          <h6>未答人数</h6>
          <strong>{{unanswered}}</strong>
        </div>
      </div>
      <ul class="student-list" v-if="total">
        <li v-for="(item, index) in studentList" :key="index">
          <div class="stuser_info">
            <el-avatar :size="40" :src="item.stuser_image" />
            <div>
              <div class="name_answer">
                <div class="name">{{ item.stuser_name }}</div>
                <div class="answer" v-if="!isChoice(syque_typ_id)">
                  <el-image style=" width: 20px; height: 30px; border: 1px solid #999999;" v-if="item.picImg" fit="cover" :src="item.picImg" :preview-src-list="[item.picImg]"/>
                </div>
              </div>
              <div class="date" v-if="item.practice_status!=0||item.picImg">
                答题用时：{{ item.questionTime }} 秒
              </div>
              <div class="date" v-else>
                待解答
              </div>
            </div>
          </div>
          <div class="result">
            <div class="type">
              <div class="dian color1" v-if="item.practice_status==1"></div>
              <div class="dian color1" v-if="item.picImg"></div>
            </div>
            <div class="type" v-if="isChoice(syque_typ_id)">
              <div class="dian color2" v-if="item.practice_status==2"></div>
            </div>
            <div class="type">
              <div class="dian color3" v-if="item.practice_status==0&&$isEmpty(item.picImg)"></div>
            </div>
          </div>
        </li>
      </ul>
      <!-- 刷新页面 -->
      <div class="refresh" @click="showSingleAnswer({syque_typ_id,teles_pra_id})" v-loading="showSingleAnswerLoading">
        <el-button type="primary" icon="el-icon-refresh" circle></el-button>
      </div>
    </div>
    </el-dialog>
    <!-- 展示随机选中的学生 -->
     <drawStudent style="position: fixed;z-index: 1000;" v-model="showStudent" :userList="submitList.userList" @colseStudentFn="showStudent=false"></drawStudent>
  </section>
</template>

<script>
import {
  $getTeachCourseware,
  $getTeachPractiseAllList,
  $getTeachHomework,
  $teachHomeworkDetail2,
  $distributeSchoolHomework,
  $distributePractise,
  $overClass,
  $getSubmitList,
  $batch_assign_practice,
  $getpracticestatistics,
} from "@api/teaching";
import { $distributeHomework } from "@api/teaching";
import drawingBoard from "@comp/drawingBoard";
import drawStudent from "@comp/drawStudent";
import { formatFile, iptFloat, formatTimeStamp } from "@utils";

var datein,webofficstop;
export default {
  name: "teaching_attendClass",
  components: {
    drawingBoard,
    drawStudent
  },
  computed: {
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
    /** 判断是否是选择题 */
    isChoice() {
      const choiceTypeIds = [1, 2, 5];
      return function (val) {
        return choiceTypeIds.indexOf(val) != -1;
      };
    },
    /** 判断是否是组合题 */
    isCompose() {
      return function (val) {
        return val == 10;
      };
    },
    iptInit() {
      return function (val) {
        return iptFloat(val);
      };
    }
  },
  data() {
    return {
      teclaId: null, // 授课id
      showType: 1, // 1：课件 2：课堂练习
      drawingBoardH: 772, // 白板高度
      showDrawingBoard: false, // 打开白板
      courseware: {}, // 课件信息
      distributeLoding: false, //作业提交loading
      teachHomework: {}, // 校内作业
      teachHomeworkData: {}, // 课后作业
      practiseList: [], // 课堂练习列表
      teles_hom_id: null, // 操作的作业id
      btnload: false, // 下发按钮加载
      queload: false, // 题目加载
      sccla_id: 0, // 班级id 查询手写板列表，获取默认数据使用
      submitList: { // 学生链接数据
        userList:[]
      },
      show_ques: [], // 展示面板题目列表
      setHomeTimedialog: false, // 展示答题设置
      que_index: 0, // 当前展示题目下标
      que_compose_index: 0, // 当前展示组合题目小题下标
      current_que_list: [], // 当前题列表
      param_prefix: "", // 题目信息前缀
      home_finish_time: null, // 校内作业完成时间
      distributeDialog: false, // 打开设置作业完成时间弹窗
      distributeForm: {}, //表单
      pickerOptions: {
        disabledDate(time) {
          const dateTime = new Date();
          const startDateTime = dateTime.setDate(dateTime.getDate() - 1);
          return time.getTime() < new Date(startDateTime).getTime();
        },
        selectableRange: `00:00:00 - 23:59:59`,
      },
      // 答题时间倒计时
      sTime: 0,
      //批量加入
      allCheck: false,
      //部分选中
      someCheck: false,
      //选中小题后提交获取的组合id
      teles_pra_gro_id: 0,

      /* ----------------------- 以下是课件全屏、课件覆盖画板相关参数 ----------------------- */
      fullscreen: false, // 是否全屏预览课件
      packup: false, // 悬浮工具栏是否收起
      openboard: false, // 课件绘制画板是否显示
      board_canvas: null, // 课件画板canvas实例
      board_context: null, // 课件画板context
      pen_move_use: false, // 是否允许执行move时候绘制线条
      pen_w: 2, // 画笔宽度
      pen_color: "#fb4049", // 画笔颜色
      predefineColors: [
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      is_eraser: false, // 判断工具是画笔还是橡皮擦
      board_w: 600, // 画板宽
      board_h: 600, // 画板高
      board_l: 0,
      board_t: 0,
      //单题答题信息弹窗
      singleAnswer:false,
      //加载等待
      showSingleAnswerLoading:false,
      total: 0, //总人数
      succNum: 0, //答对人数
      errorNum: 0, //答错人数
      unanswered: 0, //未作答人数
      //答题信息
      studentList:[],
      //点击查看的题型
      syque_typ_id:0,
      //查看的小题id
      teles_pra_id:0,
      // 展示学生问答
      showStudent:false,
    };
  },
  created() {
    this.teclaId = this.$route.params.id;
    this.sccla_id = this.$route.params.sccla_id;
    //监听课程和存储的答题数据是否一直，一直回显课堂答题，不一致删除缓存
    if (sessionStorage.getItem("teclaId_by_teles_pra_gro_id")) {
      let tecla_gro = sessionStorage
        .getItem("teclaId_by_teles_pra_gro_id")
        .split("_");
      if (tecla_gro[0] == this.$route.params.id) {
        this.teles_pra_gro_id = tecla_gro[1];
      } else {
        sessionStorage.removeItem("teclaId_by_teles_pra_gro_id");
      }
    }
    //获取本地存储的答题提交组合
    this.getCourseware();
    this.getPractise();
    this.getHomework();
    this.getSubmitList();
  },
  mounted() {
    this.initHeight();
    /* 监听窗口大小变化 */
    window.addEventListener("resize", this.initHeight);
  },
  /** 监听页面离开 */
  beforeRouteLeave(to, from, next) {
    this.$destroy();
    next();
  },
  /** 销毁页面上窗口监听事件 */
  destroyed() {
    window.removeEventListener("resize", this.initHeight);
    //停止时间轮循
    clearInterval(datein);
    clearInterval(webofficstop);
  },
  methods: {
    /** 格式化最大高度 */
    initHeight() {
      this.$nextTick(() => {
        setTimeout(() => {
          const elH = this.$refs.bodyBox.clientHeight;
          this.drawingBoardH = elH;
          this.$forceUpdate();
        }, 200);
      });
    },
    /**
     * 关闭弹窗
     * @param {string} type 弹窗类型
     */
    hideDialog(type) {
      this[`${type}Dialog`] = false;
    },
    /** 下发作业 */
    async distributeHomework() {
      this.distributeLoding = true;
      let teles_hom_deadlinetime = formatTimeStamp(
        this.distributeForm.teles_hom_deadlinetime
      );

      this.hideDialog();
      let res = await $distributeHomework(this.teclaId, teles_hom_deadlinetime);
      if (res.status == 200) {
        this.$message({
          type: "success",
          duration: 1500,
          message: "作业已下发！请提醒学生完成作业",
        });
      }
      this.distributeLoding = false;
      this.setHomeTimedialog = false;
    },
    /** 下发作业时间选中 */
    choosetime() {
      let choose_time = this.distributeForm.teles_hom_deadlinetime.getTime();
      let cur_time = new Date().getTime();
      if (choose_time < cur_time) {
        this.distributeForm.teles_hom_deadlinetime = new Date();
        return this.$message.warning("不可选择当前时间之前的时间！");
      }
    },
    /**  获取课件资源 */
    async getCourseware() {
      let {data} = await $getTeachCourseware(this.teclaId);
      this.courseware=data[0].immData
      // 半个小时刷新一次
      webofficstop = setTimeout(() => {
        this.getCourseware()
      }, 60*1000*30);
    },

    /** 获取课堂练习题 */
    async getPractise() {
      let { data } = await $getTeachPractiseAllList(this.teclaId);
      this.practiseList = [...data];
    },
    /** 获取备课作业信息 */
    async getHomework() {
      let { data } = await $getTeachHomework(this.teclaId);
      this.teachHomework = data.prepareSchoolHomework || {};
      this.teachHomeworkData = data.prepareHomework || {};
      this.$forceUpdate();
    },
    /** 获取题目详情 */
    async getQueDetail() {
      this.queload = true;
      let id = this.show_ques[this.que_index][`${this.param_prefix}id`];
      /* 练习题 */
      if (this.showType == 2) {
        // 下发练习题
        this.current_que_list = this.practiseList;
      }
      /* 校内作业 */
      if (this.showType == 3) {
        this.teles_hom_id = this.teachHomework.teles_hom_id;
        let { data } = await $teachHomeworkDetail2(this.teles_hom_id, id);
        this.current_que = (!this.$isEmpty(data) && data[0]) || {};
      }
      this.queload = false;
      // 轮循获取学生链接信息
      clearInterval(datein);
      //循环获取手写板信息
      datein = setInterval(() => {
        this.getSubmitList();
      }, 3000);
      this.$forceUpdate();
    },

    /**
     * 切换文件展示类型
     * @param {number} type 1：课件   2：练习题
     */
    changeFile(type) {
      this.showType = type;
      if (type == 2) {
        this.que_index = 0;
        this.que_compose_index = 0;
        this.param_prefix = "teles_pra_";
        this.show_ques = [...this.practiseList];
        this.getQueDetail();
      }
      if (type == 3) {
        this.que_index = 0;
        this.que_compose_index = 0;
        this.param_prefix = "teque_";
        this.show_ques = [...this.teachHomework.question];
        this.getQueDetail();
      }
    },
    /**
     * 切换题目 不需要了--------------------------------------------------------
     * @param {string} type 切换类型
     */
    changeQues(type) {
      if (type == "p") {
        this.que_index = this.que_index - 1;
      } else {
        this.que_index = this.que_index + 1;
      }
      this.que_compose_index = 0;
      this.sTime = 0;
      this.getQueDetail();
    },
    /**
     * 切换小题 不需要了--------------------------------------------------------
     * @param {string} type 切换类型
     */
    changeQues_c(type) {
      if (type == "p") {
        this.que_compose_index = this.que_compose_index - 1;
      } else {
        this.que_compose_index = this.que_compose_index + 1;
      }
    },
    showSetTime() {
      this.setHomeTimedialog = true;
      this.showType = 1;
      this.distributeForm = {};
    },
    /** 下课 */
    overClass() {
      this.$msgbox({
        title: "提示",
        message: "确认结束此堂课程？",
        type: "info",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-button--custom_info",
        confirmButtonClass: "el-button--custom_primary",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          let res = await $overClass(this.teclaId);
          if (res) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "课程已结束！",
            });
            sessionStorage.removeItem("teclaId_by_teles_pra_gro_id");
            sessionStorage.setItem("teclaId", this.teclaId);
            this.$router.push({name: "TEACHING_INSTRUCT",params: {}});
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            duration: 1500,
            message: "操作已取消",
          });
        });
    },
    /**
     * 手写板
     * ---- 获取手写板在线数据
     */
    async getSubmitList() {
      let queid = this.teles_pra_gro_id;
      let res = await $getSubmitList(this.sccla_id, queid);
      this.submitList = res.data || {};
    },
    /** 题目下发 */
    async distributeQue() {
      if (this.btnload) {
        return false;
      }
      this.btnload = true;
      let res, queid;
      let list = this.current_que_list;
      //题目ids
      let pra_ids = [];
      list.map((item) => {
        if (!this.isCompose(item[`${this.param_prefix}ismakeup`])) {
          if (item.check) {
            pra_ids.push(item.teles_pra_id);
          }
        } else {
          if(item.children){
            item.children.map((item2) => {
              if (item2.check) {
                pra_ids.push(item2.teles_pra_id);
              }
            });
          }
        }
      });

      if (pra_ids.length == 0) {
        this.$message({
          message: "请选择需要下发的题",
          type: "warning",
        });
        this.btnload = false;
        return false;
      }
      /* 练习题下发 */
      if (this.showType == 2) {
        res = await $batch_assign_practice({
          tecla_id: this.teclaId,
          pra_ids: pra_ids.toString(),
        });
      }
      /* 作业下发 */
      if (this.showType == 3) {
        let numbers = Number(this.home_finish_time) * 60;
        alert("确认下发校内作业题");
        queid =
          (this.isCompose(this.current_que.teque_ismakeup) &&
            this.current_que.children[this.que_compose_index].teque_id) ||
          this.current_que.teque_id;
        let params = {
          tecla_id: this.teclaId,
          teles_hom_id: this.teles_hom_id,
          teque_id: queid,
          timeout: numbers,
        };
        this.hideDistributeDialog();
        res = await $distributeSchoolHomework(params);
      }
      this.btnload = false;
      if (res) {
        if (this.showType == 3) {
          // 倒计时处理
          this.sTime = 0;
          setTimeout(() => {
            this.repeat(numbers, (time) => {
              this.sTime = time;
              if (time == 0) {
                this.$alert("答题时间已结束!", "提示", {
                  confirmButtonText: "确定",
                  callback: (action) => {},
                });
                sessionStorage.removeItem("teles_pra_gro_id");
              }
            });
          }, 1000);
        }
        //存储正在答题的数据
        sessionStorage.setItem(
          "teclaId_by_teles_pra_gro_id",
          this.teclaId + "_" + res.data.teles_pra_gro_id
        );
        // 获取返回的那组答题id
        this.teles_pra_gro_id = res.data.teles_pra_gro_id;
        // 重新调整列表
        list.map((item) => {
          if (!this.isCompose(item[`${this.param_prefix}ismakeup`])) {
            if (item.check) {
              item.is_assign = 1;
              item.check = false;
            }
          } else {
            if(item.children){
              item.children.map((item2) => {
                if (item2.check) {
                  item2.is_assign = 1;
                  item2.check = false;
                }
              });
            }
          }
        });
        this.current_que_list = list;
        //修改全选按钮选中状态
        this.allCheck = false;
        this.someCheck = false;
        this.getQueDetail();
        this.$forceUpdate();
        this.$message({
          type: "success",
          duration: 1500,
          message: "题目下发成功",
        });
      }
    },
    /** 关闭设置作业完成时间弹窗 */
    hideDistributeDialog() {
      this.home_finish_time = null;
      this.distributeDialog = false;
    },
    //倒计时函数
    repeat(time, callbcak) {
      if (time > 0) {
        callbcak(time);
        time--;
        setTimeout(() => {
          if (this.sTime != 0) {
            this.repeat(time, callbcak);
          }
        }, 1000);
      } else {
        callbcak(0);
      }
    },
    //点击全部
    allcheckchange(val) {
      let listData = this.current_que_list;
      this.allcheck = val;
      this.someCheck = false;
      listData.map((item) => {
        item.someCheck = false;
        if (item.is_assign == 2) {
          item.check = val;
        }
        // 判断是否是组合提
        if (item.children && item.children.length > 0) {
          item.children.map((item2) => {
            if (item2.is_assign == 2) {
              item2.check = val;
            }
          });
        }
      });
      this.current_que_list = listData;
      this.$forceUpdate();
    },
    //点击单个
    checkchangeitem(check, index) {
      let listitem = this.current_que_list[index];
      if (listitem.children && listitem.children.length > 0) {
        listitem.children.map((item) => {
          if (item.is_assign == 2) {
            item.check = check;
          }
        });
      }
      this.current_que_list[index] = listitem;
      this.getallcheck();
      this.$forceUpdate();
    },
    //点击组合提子集
    checkchangechilitem(index1, index2) {
      let listitem = this.current_que_list[index1];
      let childrenitem = this.current_que_list[index1].children[index2];
      // 组合提里面选中的数量
      let clicklength = 0;
      this.current_que_list[index1].children.map((item) => {
        if (item.check) {
          clicklength++;
        }
      });
      if (clicklength > 0) {
        if (this.current_que_list[index1].children.length == clicklength) {
          this.current_que_list[index1].check = true;
          this.current_que_list[index1].someCheck = false;
        } else {
          this.current_que_list[index1].check = false;
          this.current_que_list[index1].someCheck = true;
        }
      } else {
        this.current_que_list[index1].check = false;
        this.current_que_list[index1].someCheck = false;
      }
      this.getallcheck();
      this.$forceUpdate();
    },
    //获取是否全部选中
    getallcheck() {
      // 组合提里面选中的数量
      let clicklength = 0;
      this.current_que_list.map((item) => {
        if (item.check) {
          item.someCheck = false;
          clicklength++;
        }
      });
      if (clicklength > 0) {
        //全部选中
        if (this.current_que_list.length == clicklength) {
          this.allCheck = true;
          this.someCheck = false;
        } else {
          // 部分选中
          this.allCheck = false;
          this.someCheck = true;
        }
      } else {
        this.allCheck = false;
        this.someCheck = false;
      }
      this.$forceUpdate();
    },
    //重启ap设备
    resetap() {
      this.$alert("设备已关闭,需要重新启动AP设备？", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          //打开手写板
          let url =
            "RunZhiYuanTongApServer://" + this.sccla_id + ",lisi,wusong";
          window.open(url, "_blank");
        },
      });
    },
    /* ----------------------- 以下是课件覆盖画板功能操作方法 ----------------------- */
    /** 打开/关闭画板 */
    openBoard() {
      this.openboard = !this.openboard;
      if (!this.openboard) {
        this.pen_move_use = false;
        this.is_eraser = false;
        this.boardCheckClear();
        return;
      }
      this.board_w = this.$refs.screenBox.clientWidth;
      this.board_h = this.$refs.screenBox.clientHeight;
      this.board_l = this.$refs.screenBox.offsetLeft;
      this.board_t = this.$refs.screenBox.offsetTop + 20;
      if (!this.board_canvas) {
        this.$nextTick(() => {
          this.createBoardCanvas();
        });
      } else {
        this.boardCheckClear();
      }
    },
    /** 创建画板 */
    createBoardCanvas() {
      this.board_canvas = document.getElementById("board");
      this.board_context = this.board_canvas.getContext("2d");
      this.board_context.fillStyle = "transparent";
      //动态获取canvas画布的宽、高
      this.board_context.fillRect(
        0,
        0,
        this.board_canvas.width,
        this.board_canvas.height
      );
    },
    /** 鼠标按下 */
    boardCanvasDown(e) {
      if (!this.openboard) return;
      // 让move方法可用
      this.pen_move_use = true;
      // client是基于整个页面的坐标
      // offset是cavas距离顶部以及左边的距离
      const canvasX = e.pageX - e.target.parentNode.offsetLeft - this.board_l;
      const canvasY = e.pageY - e.target.parentNode.offsetTop - this.board_t;
      // 设置canvas的配置
      if (this.is_eraser) {
        this.board_context.globalCompositeOperation = "destination-out"; // 设置橡皮擦效果
        this.board_context.lineWidth = 80; // 设置橡皮擦的大小
        this.board_context.lineCap = "round";
      } else {
        this.board_context.globalCompositeOperation = "source-over";
        this.board_context.lineWidth = this.pen_w;
        this.board_context.lineCap = "round";
        this.board_context.strokeStyle = this.pen_color;
      }
      this.board_context.beginPath(); //清除子路径
      // 移动的起点
      this.board_context.moveTo(canvasX, this.fullscreen?canvasY+20:canvasY);
    },
    /** 鼠标移动 */
    boardCanvasMove(e) {
      if (!this.openboard) return;
      if (this.pen_move_use) {
        // 只有允许移动时调用
        const t = e.target;
        let canvasX;
        let canvasY;
        // 由于手机端和pc端获取页面坐标方式不同，所以需要做出判断
        if (e.changedTouches&&e.changedTouches.length) {
          canvasX = e.changedTouches[0].pageX - t.parentNode.offsetLeft - this.board_l;
          canvasY = e.changedTouches[0].pageY - t.parentNode.offsetTop - this.board_t;
        } else {
          canvasX = e.pageX - t.parentNode.offsetLeft - this.board_l;
          canvasY = e.pageY - t.parentNode.offsetTop - this.board_t;
        }
        // 连接到移动的位置并上色
        this.board_context.lineTo(canvasX, this.fullscreen?canvasY+20:canvasY);
        this.board_context.stroke();
      }
    },
    /** 鼠标松开 */
    boardCanvasUp() {
      // 设置move时不可绘制
      this.pen_move_use = false;
    },
    /** 清除画板绘制数据 */
    boardCheckClear() {
      this.board_context.clearRect(
        0,
        0,
        this.board_canvas.width,
        this.board_canvas.height
      );
    },
    /** 判断 PC || WAP */
    isPc() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return !flag;
    },
    //全屏
    setfullscreen(){
      if(this.fullscreen){
        document.exitFullscreen();
        this.fullscreen=false
      }else{
        document.documentElement.requestFullscreen();
        this.fullscreen=true
      }
    },
    //通过id获取下标
    inIdgetindex(id){
      let indexopt=0
      // 遍历父级id
      this.current_que_list.map((item,index)=>{
        if(item.teles_pra_id==parseInt(id)){
          indexopt=(index+1)<9?("0"+(index+1)):(index+1)
        }
        if(item.syque_typ_id==14){
          item.children.map((item2,index2)=>{
            if(item2.teles_pra_id==parseInt(id)){
              indexopt=((index+1)<9?("0"+(index+1)):(index+1))+"_"+((index2+1)<9?("0"+(index2+1)):(index2+1))
            }
          })
        }
      })
      return indexopt
    },
    // 正在查看的答题信息
    async showSingleAnswer(item) {
      let params ={
        tecla_id: this.teclaId,
        teles_pra_id:item.teles_pra_id
      }
      this.syque_typ_id=item.syque_typ_id
      this.teles_pra_id=item.teles_pra_id
      this.showSingleAnswerLoading=true
      let res = await $getpracticestatistics(params);
      if(res){
        let data=res.data
        this.total = data.total; //总人数
        this.succNum = data.succNum; //答对人数
        this.errorNum = data.errorNum; //答错人数
        this.unanswered = data.unanswered; //未作答人数
        this.studentList = data.studentList;  //答题信息
        this.singleAnswer=true
      }
      this.showSingleAnswerLoading=false
    },
  },
};
</script>
